import { useSignMessage, useWalletClient } from 'wagmi';

export const useEnhanceSignMessage = () => {
  const { signMessageAsync } = useSignMessage();
  const { data } = useWalletClient();

  // signMessageAsync required signer behind the scene, so we need to wait for signer to be ready
  if (data) {
    return { signMessageAsync };
  } else {
    return { signMessageAsync: null };
  }
};

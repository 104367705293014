import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { axiosClient } from "@/lib/axiosClient";

interface AxiosMutationParams<TData> {
  url: string;
  method: "post" | "put" | "get";
  axiosInstance?: typeof axiosClient;
}

const useMyMutation = <TData = unknown, TVariables = unknown>(
  { url, method, axiosInstance = axiosClient }: AxiosMutationParams<TData>,
  options?: UseMutationOptions<TData, Error, TVariables>,
) => {
  const mutation = useMutation({
    mutationFn: async (variables: TVariables): Promise<TData> => {
      const response = await axiosInstance({
        url,
        method,
        data: variables,
      });
      return response.data;
    },
    ...options,
  });

  return {
    ...mutation,
    mutate: (variables: TVariables) =>
      new Promise<TData>((resolve, reject) => {
        mutation.mutate(variables, {
          onSuccess: data => resolve(data),
          onError: error => reject(error),
        });
      }),
  };
};

export default useMyMutation;

"use client";

import ChainData from "@/constant/chains.json";
import { getDefaultConfig, RainbowKitProvider, useConnectModal } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import jwt_decode from "jwt-decode";
import { keyBy } from "lodash";
import type { PropsWithChildren } from "react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAccount, useDisconnect, WagmiProvider } from "wagmi";
import { http } from "viem";

import GoogleIcon from "@/asset/images/sign-in/google.svg";
import MetamaskIcon from "@/asset/images/sign-in/metamask.svg";
import TrustWalletIcon from "@/asset/images/sign-in/trust-wallet-icon.svg";
import { WALLET_CONNECT_PROJECT_ID } from "@/constant/apiPath";
import { signatureKey } from "@/constant/types";
import { addressShorter, convertExpiredTime, isExpired } from "@/helpers";
import { createContext } from "@/helpers/createContext";
import useAxiosQuery from "@/hooks/useMyQuery";
import useMyMutation from "@/hooks/userMyMutation";
import { useEnhanceSignMessage } from "@/hooks/useSignMessage";
import type { ILoginWalletResponse } from "@/models/auth";
import type { UserPlatformIF } from "@/models/userPlatform";
import { useGoogleLogin } from "@react-oauth/google";
import { Button, Divider, Modal, notification } from "antd";
import axios from "axios";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { usePathname, useRouter } from "next/navigation";
import { encode } from "base-64";
import ReactGA from "react-ga";
import { bsc, bscTestnet, mainnet, polygon } from "viem/chains";

export type AuthState = "notLogin" | "loading" | "signingIn" | "error" | "logged" | "noRef";
export type SignType = "Logged-in" | "Add Wallet";

interface PersonalIF {
  first_name?: string;
  last_name?: string;
  phone?: string;
}

interface ProfileIF {
  avatar?: string;
  display_name?: string;
  bio?: string;
}

interface AuthContext {
  handleLoginGoogle: () => void;
  handleLoginWallet: () => void;
  onDisconnectWallet: () => void;
  logout: () => void;
  user: UserInfo | undefined | null;
  username: string;
  authState: AuthState;
  token: TokenBlob | undefined | null;
  setToken: React.Dispatch<React.SetStateAction<TokenBlob | null | undefined>>;
  address?: string;
  isNoConnectGmail: boolean;
  isLoggedIn: boolean;
  mutateUser: () => void;
  openLoginModal: () => void;
  closeLoginModal: () => void;
  isLoginModalVisible: boolean;
  handleLaunchAppWithToken: (url?: string, openNewTab?: boolean) => void;
}

export interface TokenBlob {
  walletAddress?: string;
  exp?: number;
  signature?: string;
  isNoRef?: boolean;
  accessToken?: string;
}

export interface UserInfo {
  id: string;
  username: string;
  email: string;
  enable: boolean;
  nonce?: string;
  role: "user" | "admin";
  status: string;
  referral_code?: string;
  updated_at: string;
  created_at: string;
  wallets: string[];
  user_platform?: UserPlatformIF[];
  personal_information?: PersonalIF;
  profile_information?: ProfileIF;
  recovery_email?: string;
  reward_point?: number;
  is_email_confirmed?: boolean;
  toggle_summary?: boolean;
  toggle_session?: boolean;
  is_feature_email?: boolean;
  verify_date?: string;
}

const [Provider, useAuth] = createContext<AuthContext>();

export const getStoredSignature = (): TokenBlob | undefined => {
  try {
    const data = JSON.parse(window.localStorage.getItem(signatureKey) || "{}");
    if (isExpired(data?.exp || data?.expires_in || 0)) {
      return undefined;
    }
    return data;
  } catch {
    return undefined;
  }
};

export const AProvider = ({ children }: PropsWithChildren) => {
  const pathname = usePathname();
  const { openConnectModal } = useConnectModal();
  const { address, isConnected } = useAccount();
  const [ipAddress, setIPAddress] = useState<string>("");
  const [isNoConnectGmail, setIsNoConnectGmail] = useState<boolean>(false);
  const { signMessageAsync } = useEnhanceSignMessage();
  const [token, setToken] = useState<TokenBlob | undefined | null>(null);
  const { disconnect } = useDisconnect();
  const t = useTranslations();
  const [isOAuthVerified, setIsOAuthVerified] = useState(false);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [requestSignWallet, setRequestSignWallet] = useState<SignType | undefined>();

  const { data: userData, refetch: mutateUser } = useAxiosQuery<{ data: UserInfo }>({
    url: `/v1/user/detail`,
    method: "post",
    enabled: !!token,
  });

  const { mutate: mutateAuth } = useMyMutation<{ data: ILoginWalletResponse }>({
    url: `/v1/auth`,
    method: "post",
  });
  
  const user = useMemo(() => userData?.data, [userData]);

  const isLoggedIn = useMemo(() => isConnected || isOAuthVerified, [isConnected, isOAuthVerified]);

  const router = useRouter();

  const authState = useMemo<AuthState>(() => {
    if (token === undefined) {
      // Not get token access yet
      return "notLogin";
    } else if (token === null || (user === undefined && token.accessToken)) {
      // Loading token or loading user info
      return "loading";
    } else if (user === undefined && !token.accessToken) {
      // Under login to account
      return "signingIn";
    } else if (user && token.accessToken) {
      // Login success
      return "logged";
    } else if (user === null && token.accessToken) {
      // Load user info fail or token expired
      return "error";
    } else {
      // Others case, not login
      return "notLogin";
    }
  }, [token, user]);

  const onDisconnect = useCallback(async () => {
    await disconnect();
  }, [disconnect]);

  const openLoginModal = useCallback(() => {
    setIsLoginModalVisible(true);
  }, []);

  const closeLoginModal = useCallback(() => {
    setIsLoginModalVisible(false);
  }, []);

  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize(process.env.NEXT_PUBLIC_GA_TRACKING_ID || "G-9ZYWCSJ54M");

    // Track page-view on path change
    console.log("pathname", pathname);
    if (pathname) {
      ReactGA.pageview(pathname);
    }
  }, [pathname]);

  useEffect(() => {
    // Get Token
    const token = getStoredSignature();
    setToken(token);
    // Get Ip Address
    fetch("https://api.ipify.org?format=json")
      .then(response => response.json())
      .then(data => setIPAddress(data.ip))
      .catch(error => console.log(error));
  }, []);

  useEffect(() => {
    if (requestSignWallet && isConnected && address && signMessageAsync) {
      (async () => {
        try {
          setRequestSignWallet(undefined);

          let nonce: string | undefined;
          if (requestSignWallet === "Logged-in") {
            window.localStorage.setItem(signatureKey, "");
            setToken(null);
            const res = await axios.get(`/v1/auth/nonce`, {
              params: {
                walletAddress: address,
              },
            });
            nonce = res?.data?.data?.nonce?.toString();
          } else {
            nonce = user?.nonce?.toString();
          }

          const signature = await signMessageAsync({
            message: nonce as string,
          });

          const res = await mutateAuth({
            signature: signature.replace("0x", ""),
            walletAddress: address,
            ip_address: ipAddress,
          });

          const userAuth = res?.data;
          if (userAuth?.accessToken) {
            const data: { exp: number } = jwt_decode(userAuth.accessToken);
            // Sign success
            const token = {
              accessToken: userAuth.accessToken,
              signature: signature.replace("Ox", ""),
              walletAddress: address,
              exp: data.exp,
              isNoRef: !userAuth.use_ref,
            } as TokenBlob;
            setToken(token);
            window.localStorage.setItem(signatureKey, JSON.stringify(token));
            closeLoginModal();
          }
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [requestSignWallet, user, isConnected, address, signMessageAsync, ipAddress]);

  const handleSignWallet = useCallback(
    async (type: SignType) => {
      await onDisconnect();
      if (openConnectModal) {
        setRequestSignWallet(type);
        openConnectModal();
        closeLoginModal();
      }
      return undefined;
    },
    [onDisconnect, openConnectModal],
  );

  const handleLoginGoogle = useGoogleLogin({
    onSuccess: response => {
      const { access_token, expires_in } = response;
      axios
        .post(`/v1/auth/social_login`, {
          token: access_token,
          expires_in,
        })
        .then(response => {
          const result = {
            ...{ ...(response.data.data || {}) },
            exp: convertExpiredTime(response?.data?.data?.expires_in),
          };
          if (result) {
            setIsNoConnectGmail(false);
            setIsOAuthVerified(true);
            closeLoginModal();
            window.localStorage.setItem(signatureKey, JSON.stringify(result));
            setToken(result);
            mutateUser();
          }
        })
        .catch(err => {
          const message = err?.response?.data?.message || err?.message;
          notification.error({
            duration: 10000,
            message: t("Log-in - Failed"),
            description: t("Google signIn fail! {{error_message}}", {
              error_message: message ? message : t("Please try again."),
            }),
          });
          setToken(undefined);
        });
    },
    onError: error => {
      const message = error.error_description;
      notification.error({
        duration: 10000,
        message: t("Log-in - Failed"),
        description: t("Google signIn fail! {{error_message}}", {
          error_message: message ? message : t("Please try again."),
        }),
      });
    },
    flow: "implicit",
  });

  const logout = useCallback(async () => {
    try {
      //
    } catch (err) {
      console.log(err);
    } finally {
      if (address) {
        await onDisconnect();
      }
      router.push("/");
      window.localStorage.setItem(signatureKey, "");
      setToken(null);
      setIsOAuthVerified(false);
    }
  }, [address, onDisconnect, setIsOAuthVerified]);

  const username = useMemo(
    () =>
      user?.profile_information?.display_name ||
      user?.username ||
      user?.email ||
      addressShorter(user?.wallets && user.wallets[0]),
    [user],
  );

  const handleLaunchAppWithToken = (url?: string, openNewTab = false) => {
    const encodeToken = token ? encode(JSON.stringify(token)) : "";
    if (url === process.env.NEXT_PUBLIC_KLARDA_APP_URL && !token) {
      window.open(url, "_blank");
      return;
    }
    if (url) {
      openNewTab
        ? window.open(`${url}?${token ? `token=${JSON.stringify(encodeToken)}` : ""}`, "_blank")
        : router.replace(`${url}?${token ? `token=${JSON.stringify(encodeToken)}` : ""}`);
    } else {
      router.replace(`${process.env.NEXT_PUBLIC_KLARDA_APP}?token=${JSON.stringify(encodeToken)}`);
    }
  };

  return (
    <Provider
      value={{
        handleLoginWallet: () => handleSignWallet("Logged-in"),
        onDisconnectWallet: onDisconnect,
        logout,
        authState,
        handleLoginGoogle,
        user,
        isNoConnectGmail,
        username,
        token,
        setToken,
        address,
        isLoggedIn,
        mutateUser,
        openLoginModal,
        closeLoginModal,
        isLoginModalVisible,
        handleLaunchAppWithToken,
      }}
    >
      {children}
      <Modal
        open={isLoginModalVisible}
        onCancel={closeLoginModal}
        footer={null}
        width={400}
        className="rounded-lg"
        closable={true}
        centered
      >
        <div className="flex flex-col items-center py-6">
          <h2 className="text-xl font-semibold mb-6">{t("sign_in.welcome")}</h2>

          <div className="w-full space-y-4">
            <Button
              size="large"
              className="btn-google text-base font-medium w-full"
              onClick={() => handleLoginGoogle()}
            >
              <div className="flex gap-2 justify-center">
                <Image alt="gg icon" src={GoogleIcon} />
                {t("sign_in.Sign_in_with_google")}
              </div>
            </Button>

            <Divider>{t("sign_in.or")}</Divider>

            <Button
              size="large"
              className="btn-metamask text-base font-medium w-full"
              onClick={() => handleSignWallet("Logged-in")}
            >
              <div className="flex gap-2 justify-center">
                <Image alt="wallet icon" src={MetamaskIcon} />
                <Image alt="wallet icon" src={TrustWalletIcon} />
                {t("sign_in.Sign_in_with_wallet")}
              </div>
            </Button>
          </div>

          <div className="mt-6 text-xs text-gray-500">
            {t.rich("sign_in.Help_report", {
              help: chunk => (
                <button
                  onClick={() => window.open("https://support.klarda.com", "_blank")}
                  className="text-blue-500 hover:underline ml-1"
                >
                  {chunk}
                </button>
              ),
            })}
          </div>
        </div>
      </Modal>
    </Provider>
  );
};

export { useAuth };

const klardaChains = keyBy(
  ChainData.filter(chain => ["Ethereum", "BSC", "Polygon", "Fantom Opera", "Cronos", "Avalanche"].includes(chain.name)),
  "name",
);

const config = getDefaultConfig({
  appName: "Klarda",
  projectId: WALLET_CONNECT_PROJECT_ID,
  chains: [bsc],
  transports: {
    [bsc.id]: http("https://bsc-dataseed.binance.org/"),
  },
});
export const AuthProvider = ({ children }: PropsWithChildren) => (
  <WagmiProvider config={config}>
    <RainbowKitProvider modalSize="compact">
      <AProvider>{children}</AProvider>
    </RainbowKitProvider>
  </WagmiProvider>
);
